/* allblogs.css */

.deals-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20%;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
  }
  
  .card {
    text-align: left;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    /* justify-content: center; Center content vertically */
     /* Center content horizontally */
    /* height: 350px; Remove height to let Flexbox control the height */
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-image {
    align-self: center;
    width: 150px;
    height: 150px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    border-radius: 5%;
    margin-bottom: 10px;
  }
  
  .card-title {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-content {
    /* Use nested Flexbox to align title to the left and center the button */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .card-description {
    text-align: left;
    font-size: 12px;
    margin-bottom: 25%;
    margin-top: 5px;
  }
  
  .card-original-description {
    text-align: left;
    font-size: 12px;
    color: #777;
    margin-bottom: 5%;
    margin-top: 5px;
  }
  
 
  
  
  .goToPage {
    background-color: transparent;
    border: 2px solid rgb(67, 7, 80);
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    margin-top: 50%;
    margin-bottom: 20px;
  
    position: absolute; /* Add absolute positioning */
    bottom: 0px; 
    align-self: center; /* Center the button horizontally */
  }
  
  .goToPage:hover {
    background-color: rgb(67, 7, 80);
    color: white;
    cursor: pointer;
  }
  

  