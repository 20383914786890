@tailwind base;
@tailwind components;
@tailwind utilities;


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: white;
}

.logopic {
    height: 18%;
    width: 18%;
    margin-left: auto;
    border-radius: 50%!important;
    /* padding: 5%; */
    margin-right: auto;
}

.color-nav {
    /* box-shadow: 2px 3px 7px 0px rgb(128, 128, 128); */
    /* background: url("../public/ep_naturalwhite.png") center center repeat; */
    /* border-top: 1px solid #999;
    border-bottom: 1px solid #999; */
    background-color: #240032;
    /* opacity: 0.6; */
}

#link {
    text-align: center;
}

#navLinks,
#collasible-nav-dropdown {
    /* color: rgb(255, 102, 102); */
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
    background-color: #240032;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
}

a.dropDown.dropdown-item {
    /* background-color: #240032; */
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
}

.dropdown-menu.show {
    background-color: #240032;
    
}

button.navbar-toggler {    
    background-image: linear-gradient(to right, #FF9966, #FF6699);
}

.overlay-text {
    position: absolute;
    top: 40%;
    left: 49%;
    transform: translate(-50%, -50%); /* Centers the text */
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 25px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity, change as needed */
    padding: 10px;
    border-radius: 5px; /* Optional: to give a rounded corner to the text background */
  }

  .text-sm {
   
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
   
    font-weight: bold;
   
  }
  

pre {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    /* clear: both; */
    color: #fff;
    background: #240032;
    width: auto;
    /* padding: 0; */
    text-align: left;
    /* -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2; */
    /* -ms-word-break: normal;
    word-break: normal;  
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none; */
    /* position: relative; */
    display: block;
    line-height: 28px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
}

code {
    font-size: 11px;
    padding: 15px 20px 10px 14px;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
}

#pictext {
    position: absolute;
    margin-top: 20%;
    margin-left: 35%;
    transform: translate(-50%, -50%);
    display: grid;
    max-width: auto;
    max-height: auto;
    padding: auto;
    /* margin-right: auto;
    margin-left: auto; */
}

#pictexttwo {
    /* text-align: left; */
    letter-spacing: 1px;
    text-align: center;
    font-weight: bold;
    font-size: 10.2vw;
    color: #fdcbfe;
    text-shadow: 2px 2px #c1899b;
    animation-name: reactword;
    animation-duration: 5s;
}

@keyframes reactword {
    0% {
        color: #fdcbfe;
        text-shadow: 2px 2px #c1899b;
    }
    25% {
        color: #d57baf;
        text-shadow: 2px 2px #c1899b;
    }
    50% {
        color: #be9eee;
        text-shadow: 2px 2px #a17ae9;
    }
    100% {
        color: #004aad;
        text-shadow: 2px 2px #232b73;
    }
}


/* h2 is for the title */

h2 {
    text-align: center;
    padding: 25px;
    /* color: #555; */
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
}


/* under pic header */

h5 {
    text-align: center;
    padding: 25px;
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
}

h6 {
    text-align: center;
    padding: 25px;
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
}

#impostorHr {
    width: 215px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -17px;
    margin-bottom: 1%;
}

#learningHr {
    width: 385px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -17px;
    margin-bottom: 1%;
}

#hr30Days {
    width: 380px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -17px;
    margin-bottom: 1%;
}

#whoAmIHr {
    width: 180px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -17px;
    margin-bottom: 3%;
}

p {
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid transparent;
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
    display: block;
    text-align: left;
    margin-top: 0;
    margin-bottom: 1rem;
}

.text {
    margin-right: auto;
    margin-left: auto;
    text-align: left;
}

.wrapper {
    display: grid;
    margin: 0 auto;
    padding: 25px;
    margin-left: 10%;
    margin-right: 10%;
}


/* My picture */

.img-fluid {
    border-radius: 50%!important;
    max-width: 75%;
    height: auto;
    vertical-align: middle;
    border-style: none;
    margin-top: 50px;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
}


/* My picture */

.imgWrapper {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    /* padding: 5px; */
    height: auto;
    width: 40%;
}


/* This makes front page pix fluid */

.img-fluidtwo {
   
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden; 
}

.imgWrappertwo {
   
    object-fit: cover;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    padding: auto;
    height: auto;
    width: auto;
}

.shooting-star {
    width: 10px;
    height: 5px;
    background-color: #FFF;
    position: absolute;
    /* top: 0;
    left: 0; */
    border-radius: 60%;
    animation: shooting 2s infinite;
}

.shooting-star::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px; /* Adjust to control tail's starting point */
    width: 27px;
    height: 5px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.9), transparent);
    border-radius: 50%;
  }

@keyframes shooting {
    0% {
        top: 0%;
        left: 0%;
    }
    100% {
        top: 50%;
        left: 100%;
        transform: translateX(-100%); 
    }
}




/* This makes all other pages pix fluid */
.working{
    border-style: none;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
}

.img-fluidthree {
    border-radius: 1%!important;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
}

.imgWrapperthree {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    margin-top: -35px;
    padding: 15px;
    height: auto;
    width: 100%;
    justify-content: center;
}


/* This makes header pix fluid */

.img-fluidfour {
    max-width: 100%;
    width: 100%;
    opacity: 0.9;
    height: 20rem;
    border-style: none;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
}

.imgWrapperfour {
    display: grid;
    height: 20%;
    margin-left: auto;
    margin-right: auto;
}


/* page footer */

.page-footer {
    background-color: #240032;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
   
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    
    /* opacity: 0.6; */
   
    font-weight: bold;
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
}

#footerName {
    
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
}

.video-gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* adjust as needed */
    gap: 50px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30%;
  }
  
/* Styling for the video */
.video-item {
    width: 100%;         /* Make the video expand to the full width of its container */
    /* height: 100%; */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
}
  
  /* Container for each video and its description */
.video-with-description {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center the content horizontally */
    margin: 15px;         /* Space between each video block */
    max-width: 300px;     /* Set a maximum width if you want */
}



/* Styling for the video description */
.video-description {
    margin-top: 8px;         /* Space between video and description */
    font-size: 14px;         /* Adjust font size as needed */
    text-align: left;        /* Align text to the left */
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
    width: 90%;              /* Adjust as needed */
    margin-left: auto;       /* These two properties center the block */
    margin-right: auto;      /* horizontally in its container */

}

/* Styling for the project name */
.video-title {
    margin-top: 15px;    /* Space above the project name */
    font-weight: bold;   /* Bold font */
    font-size: 15px;     /* Font size for the title */
    color: transparent;
    background-image: linear-gradient(to right, #FF9966, #FF6699);
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase; /* Makes the text uppercase */
}

.italic {
    color: #240032;
}

.parent-of-goBack {
    display: flex;           
     /* Makes the container a flex container */
    justify-content: center;  
    /* Center children horizontally */
    align-items: flex-end;    
    /* Align children to the bottom */
    /* min-height: 100vh;         */
    /* Assuming you want it at the bottom of the viewport */
    /* padding-bottom: 20px;     */
     /* Provide some space at the bottom */
  }
  
  .goBack {
    background-color: transparent;
    border: 2px solid rgb(67, 7, 80);
    min-width: 100px;
    padding: 5px 10px;
    border-radius: 15px;
  }
  
  .goBack:hover {
    background-color: rgb(67, 7, 80);
    color: white;
    cursor: pointer;
  }
  

  