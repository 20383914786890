@tailwind base;
@tailwind components;
@tailwind utilities;

.email {
    text-align: center;
    max-width: 300px;
    margin: 20px auto;
  }
  
  Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 3px 7px 0px rgb(128, 128, 128);
  
  }

  .g-recaptcha {
    transform:scale(0.85);
    -webkit-transform:scal(0.85);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    
    } 