.products {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 10px;
    place-items: center;
}

.gadgets {
    border-radius: 15px;
    width: 200px;
    height: 350px;
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gadgets img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    /* height: 200px; */
}

.gadgets .description {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gadgets:hover {
    transition: 0.3s ease-in;
    cursor: pointer;
}

.addToCartBttn {
    background-color: transparent;
    border: 2px solid rgb(19, 19, 19);
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
   
  
    /* position: absolute; Add absolute positioning */
    /* bottom: 10px;  */
}

.addToCartBttn:hover {
    background-color: rgb(19, 19, 19);
    color: white;
    cursor: pointer;
}

h4 {
    font-size: 25px;
}

small {
    text-align: left;
}